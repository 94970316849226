<ion-app>
  <ion-split-pane contentId="mainContent" when="xxl">
    <ion-menu contentId="mainContent" type="overlay">
      <ion-content>
        <div class="ion-padding">
          <p>Pfaffenhofen App</p>
        </div>

        <ion-list class="ion-no-padding mt-6">
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'root'" [routerLink]="'/news'" lines="none">
              <ion-label>News</ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/events'" lines="none">
              <ion-label>Events</ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/places'" lines="none">
              <ion-label>Orte</ion-label>
            </ion-item>
            @if (settingsService.settings().features.articles) {
              <ion-item [routerDirection]="'root'" [routerLink]="'/articles'" lines="none">
                <ion-label>Nachbarschafts-Netzwerk</ion-label>
              </ion-item>
            }
            <ion-item [routerDirection]="'root'" [routerLink]="'/user/login'" lines="none">
              <ion-label>Meins</ion-label>
            </ion-item>

            <ion-item [routerDirection]="'root'" [routerLink]="'/contact'" lines="none">
              <ion-label>Kontakt</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

      </ion-content>

      <ion-footer>
        <ion-list class="ion-no-padding">
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'root'" [routerLink]="'/privacy'" lines="none">
              <ion-label>
                Datenschutz
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/imprint'" lines="none">
              <ion-label>
                Impressum
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet #routerOutlet id="mainContent" [swipeGesture]="true"></ion-router-outlet>
    <app-a2hs-browser-prompt/>
  </ion-split-pane>
</ion-app>
