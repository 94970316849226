import {effect, inject, Injectable, signal, WritableSignal} from "@angular/core";
import {ApiService, AuthService} from "@app/services";
import {Settings} from "@interfaces/settings";
import {firstValueFrom} from "rxjs";
import {User} from "@app/interfaces";

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ApiService {

  settings: WritableSignal<Settings> = signal<Settings>({
    features: {
      news: true,
      events: true,
      articles: false,
      polls: false,
    }
  } as Settings);

  private authService = inject(AuthService);
  private currentUser: User | null = null;

  constructor() {
    super();
    this.fetch();

    effect(() => {
      const user = this.authService.currentUser();
      console.log(`${this.constructor.name}.effect`, this.currentUser, user);
      if (JSON.stringify(user) !== JSON.stringify(this.currentUser)) {
        console.log(`${this.constructor.name}.effect: users differ`);
        this.fetch();
      }

      this.currentUser = user;
    });
  }

  async fetch(): Promise<Settings> {
    const settings: Settings = await firstValueFrom(this.get<Settings>(`/settings`));
    this.settings.set(settings);
    return settings;
  }

}
