import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  Signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {Event, Owner} from "@app/interfaces";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {AuthService, CameraService} from "@app/services";
import {EventsService} from "@services/api";
import {
  AlertController,
  IonButton,
  IonButtons,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  LoadingController,
  NavController
} from "@ionic/angular/standalone";
import {addIcons} from "ionicons";
import {camera, close, videocam} from "ionicons/icons";
import {formatISO, roundToNearestMinutes} from "date-fns";
import {HttpEventType} from "@angular/common/http";
import {environment} from "@env/environment";
import {firstValueFrom} from "rxjs";
import {NgIf} from "@angular/common";
import {EventsRepository} from "@app/repositories/events.repository";
import {TakePictureComponent} from "@app/components/form/take-picture/take-picture.component";
import {flare} from "@flareapp/flare-client";

@Component({
  selector: 'app-update-event-sheet',
  templateUrl: './update-event-sheet.component.html',
  styleUrls: ['./update-event-sheet.component.scss'],
  imports: [
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonFooter,
    IonDatetime,
    IonDatetimeButton,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    NgIf,
    ReactiveFormsModule,
    TakePictureComponent
  ]
})
export class UpdateEventSheetComponent implements OnInit {

  @Input() model: Event;
  @ViewChild('photo', {static: false}) photo: HTMLImageElement | undefined;

  public places: Signal<any> = computed(() => this.authService.currentUser()?.places);
  public isLoading: WritableSignal<boolean> = signal(false);

  public form: FormGroup;
  public hasPhoto: boolean = false;
  public minDate: string = formatISO(roundToNearestMinutes(new Date(), {nearestTo: 15, roundingMethod: 'ceil'}));

  protected uploadProgress: number;
  protected loading: HTMLIonLoadingElement | undefined;

  private selectedVideo: PickedFile | null = null;

  private el: ElementRef = inject(ElementRef);
  private fb: FormBuilder = inject(FormBuilder);
  private authService: AuthService = inject(AuthService);
  private eventsService: EventsService = inject(EventsService);
  private eventsRepository: EventsRepository = inject(EventsRepository);
  private cameraService: CameraService = inject(CameraService);
  private alertController: AlertController = inject(AlertController);
  private loadingController: LoadingController = inject(LoadingController);
  private navController: NavController = inject(NavController);

  constructor() {
    console.log(`${this.constructor.name}.constructor`, this.model);
    addIcons({close, camera, videocam});

    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(5000)]],
      event_date_start: [this.minDate, [Validators.required]],
      place_id: [null, [Validators.required]],
      photo: ['', []],
      videoPath: ['', [],],
    });

    effect(() => {
      console.log(`${this.constructor.name}.ngOnInit: places`, this.places());

      if (this.places()?.length === 1) {
        this.form.patchValue({place_id: this.places()[0].id});
      }
    });
  }

  ngOnInit() {
    console.log(`${this.constructor.name}.ngOnInit`, this.model);

    if (this.model) {
      this.form.patchValue(this.model);
    }
  }

  async save() {
    this.isLoading.set(true);

    let event: Event = this.form.value;

    event.owner = {id: this.form.get('place_id')?.value} as Owner;
    event.event_date_start = new Date(this.form.value.event_date_start).toISOString();

    if (this.form.value.event_date_end) {
      event.event_date_end = new Date(this.form.value.event_date_end).toISOString();
    }

    event.new_images = [this.form.value.photo].filter(Boolean);

    console.log(`${this.constructor.name}.onSubmit: form`, this.form.valid, this.form.errors, this.form.value);

    if (this.form.valid) {
      if (this.model.id) {
        await this.update(event);
      } else {
        await this.create(event);
      }
    } else {
      this.isLoading.set(false);
      this.form.markAllAsTouched();
    }
  }

  protected async create(eventForm: Event) {
    const event = await firstValueFrom(this.eventsService.create(eventForm)).catch((error) => {
      this.onError(error);
      return;
    });

    console.log(`${this.constructor.name}.onSubmit: response`, event);
    if (event) {
      await this.onSuccess(event);
    }
  }

  protected async update(eventForm: Event) {
    eventForm.id = this.model.id;
    eventForm.images = this.model.images;

    const event = await firstValueFrom(this.eventsService.update(eventForm)).catch((error) => {
      this.onError(error, true);
      return;
    });

    console.log(`${this.constructor.name}.onSubmit: response`, event);
    if (event) {
      await this.onSuccess(event, true);
    }
  }

  async onSuccess(event: Event, isUpdate: boolean = false) {
    this.eventsRepository.addEvent(event);

    const upload = await this.uploadVideo(event);
    console.log(`${this.constructor.name}.onSubmit: upload`, upload);
    if (upload) {
      this.eventsRepository.addEvent(upload);
    }

    const message = isUpdate ? 'Event wurde erfolgreich aktualisiert.' : 'Event wurde erfolgreich erstellt.';
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK'],
    });
    await alert.present();

    this.isLoading.set(false);
    this.close();
    await this.navController.navigateForward(`/events/${event.owner.slug}/${event.slug}`);
  }

  async onError(error: any, isUpdate: boolean = false) {
    console.error(`${this.constructor.name}.save: error`, error);

    const message = isUpdate ? 'Event konnte nicht aktualisiert werden, bitte versuche es erneut.' : 'Event konnte nicht erstellt werden, bitte versuche es erneut.';
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK'],
    });
    await alert.present();

    this.isLoading.set(false);

    flare.report(error);
  }


  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-update-event-sheet', {bubbles: true}));
  }

  async uploadVideo(event: Event): Promise<Event | null> {
    return new Promise<Event | null>(async (resolve, reject) => {
      if (!this.selectedVideo) {
        console.log(`${this.constructor.name}.uploadVideo: no video selected`);
        return resolve(null);
      }

      this.loading = await this.loadingController.create({
        message: 'Video wird hochgeladen...',
      });
      await this.loading.present();

      console.log(`${this.constructor.name}.uploadVideo: `, this.selectedVideo);

      this.eventsService.uploadVideo(event, this.selectedVideo).subscribe({
        next: async (response) => {
          if (response.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (response.loaded / response.total));
            console.log(`${this.constructor.name}.onSubmit: upload progress`, this.uploadProgress);
            if (this.loading) {
              this.loading.message = `Wird hochgeladen... ${this.uploadProgress}%`;
            }

            return;
          }

          if (response.type == HttpEventType.Response) {
            console.log(`${this.constructor.name}.onSubmit: upload response`, response);

            if (this.loading) {
              await this.loading.dismiss();
            }
            return resolve(response.body.data as Event);
          }
        },
        error: async (error) => {
          console.error(`${this.constructor.name}.onSubmit: upload error`, error);
          if (this.loading) {
            await this.loading.dismiss();
          }

          this.alertController.create({
            message: 'Video konnte nicht hochgeladen werden, bitte versuche es erneut.',
            buttons: ['OK'],
          }).then((alert) => alert.present());

          return resolve(null);
        }
      });
    });
  }

  async chooseVideo() {
    const result = await this.cameraService.selectVideo();
    if (result && result.files.length && result.files[0]) {
      const video = result.files[0];
      if (video.size > environment.maxUploadFileSize) {
        const alert = await this.alertController.create({
          message: `Die Datei ist zu groß. Bitte wähle eine Datei, die kleiner als ${environment.maxUploadFileSize / 1024 / 1024} MB ist.`,
          buttons: ['OK'],
        });
        await alert.present();
        return;
      }

      const value = result.files[0].name + ' (' + (result.files[0].size / 1024 / 1024).toFixed(0) + ' MB)';
      this.form.patchValue({videoPath: value});
      this.selectedVideo = video;
    }
  }
}
