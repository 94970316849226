import {Component, computed, inject, Signal} from '@angular/core';
import {addIcons} from "ionicons";
import {ellipse, person, square, triangle} from "ionicons/icons";
import {IonLabel, IonTabBar, IonTabButton, IonTabs, Platform} from "@ionic/angular/standalone";
import {NgIcon} from "@ng-icons/core";
import {AuthService} from "@app/services";
import {SettingsService} from "@services/api/settings.service";
import {Settings} from "@interfaces/settings";

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  imports: [IonTabs, IonTabBar, IonTabButton, IonLabel, NgIcon]
})
export class TabsPage {

  isMobile = false;
  user = computed(() => this.authService.currentUser());
  settings: Signal<Settings> = computed(() => {
    const settings = this.settingsService.settings();
    console.log('TabsPage.settings', settings);
    return settings;
  });

  private settingsService: SettingsService = inject(SettingsService);
  private platform: Platform = inject(Platform);
  private authService = inject(AuthService);

  constructor() {
    addIcons({triangle, ellipse, square, person});

    this.isMobile = this.platform.is('mobileweb');
  }

}
